export default {
    created() {
        window.addEventListener('resize', this.checkScreenSize);
        this.checkScreenSize();
    },
    destroyed() {
        this.$store.commit('hideNav/SET_HIDE_NAV', false);
        window.removeEventListener('resize', this.checkScreenSize);
    },
    methods: {
        checkScreenSize() {
            const isMedium = window.innerWidth < 768;
            this.$store.commit('hideNav/SET_HIDE_NAV', isMedium);
        },
    }
}