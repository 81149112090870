<template>
  <div class="layout-main detail-sub-page group-detail-member-edit custom-z-index">
    <div class="journey" v-if="groupData && journeyData && joinedMembers">
      <div class="page-nav">
        <router-link class="back-btn" :to="{name: backRouterName}">
          <Icon name="arrow_back_ios" size="24" />
        </router-link>
        <h2 class="fs-h2">參與人數</h2>
        <h5 class="fs-h5 d-none d-md-block">
          <span>（ </span>
          <span v-text="joinedMembers.groupList?.length + 1"></span>
          <span> / </span>
          <span v-text="groupData.joinLimit"></span>
          <span> ）</span>
        </h5>
        <div class="btn option-btn common-oval-btn dark fs-body-18" @click="updateMemberSettings">儲存</div>
      </div>
      <div class="page-content">
        <div class="team-members-number with-hr d-flex justify-content-between align-items-center">
          <div class="fs-body-16">開放參團</div>
          <b-form-checkbox v-model="isJoinOpen" name="check-button" switch @change="showJoinFlowState">
          </b-form-checkbox>
        </div>
        <div class="team-members-number with-hr d-flex justify-content-between align-items-center">
          <div class="fs-body-16">人數設定</div>
          <b-form-select v-model="setJoinSubmit.joinLimit" :options="numberOptions"></b-form-select>
        </div>
        <div class="team-members-number with-hr d-flex align-items-center">
          <div class="avatar-group">
            <div class="avatar circle-trail">
              <img :src="joinedMembers.owner?.pic_url" alt="avatar">
            </div>
          </div>
          <div class="fs-body-14">
            <span>主辦人</span>
          </div>
        </div>

        <div class="team-members-number with-hr d-flex" v-for="(item, index) in joinedMembers.groupList" :key="index">
          <div class="w-100 d-flex justify-content-between">
            <div class="d-flex">
              <div class="avatar-group">
                <div class="avatar circle-trail">
                  <img :src="item.user.pic_url" alt="avatar">
                </div>
                <div class="edit-link" v-if="item.user.serial === user.serial && !isExpired">
                  <router-link :to="{ name: 'GroupDetailMemberJoin' }">編輯</router-link>
                </div>
              </div>
              <div class="no-permission fs-body-14 align-items-center d-flex" v-if="isExpired || !item.realName">
                <span>無瀏覽資料權限</span>
              </div>
              <!--								<div class="no-permission fs-body-14 align-items-center d-flex" v-if="!isExpired && !item.realName">-->
              <!--									<span>無瀏覽資料權限</span>-->
              <!--								</div>-->
              <div class="detail-list fs-body-14 align-items-center" v-if="!isExpired">
                <div class="detail-item cursor-pointer" v-if="item.realName" @click="getTextCopy($event)">
                  <div class="item-title">姓名</div>
                  <div class="item-desc" v-text="item.realName"></div>
                  <span class="success-alert"></span>
                </div>
                <div class="detail-item cursor-pointer" v-if="item.phone" @click="getTextCopy($event)">
                  <div class="item-title">電話</div>
                  <div class="item-desc" v-text="item.phone"></div>
                  <span class="success-alert"></span>
                </div>
                <div class="detail-item cursor-pointer" v-if="item.address" @click="getTextCopy($event)">
                  <div class="w-100 d-flex flex-nowrap">
                    <div class="item-title">地址</div>
                    <div class="item-desc" v-text="(item.fullAddress && item.fullAddress.length>0)? item.fullAddress : item.address"></div>
                  </div>
                  <span class="success-alert"></span>
                </div>

                <div class="detail-item cursor-pointer" v-if="item.email" @click="getTextCopy($event)">
                  <div class="item-title">Email</div>
                  <div class="item-desc" v-text="item.email"></div>
                  <span class="success-alert"></span>
                </div>
                <div class="detail-item cursor-pointer" v-if="item.personalId" @click="getTextCopy($event)">
                  <div class="item-title">身分證字號</div>
                  <div class="item-desc" v-text="item.personalId"></div>
                  <span class="success-alert"></span>
                </div>
                <div class="detail-item cursor-pointer" v-if="item.birthday" @click="getTextCopy($event)">
                  <div class="item-title">出生年月日</div>
                  <div class="item-desc" v-text="item.birthday"></div>
                  <span class="success-alert"></span>
                </div>
                <div class="detail-item cursor-pointer" v-if="item.emergencyName" @click="getTextCopy($event)">
                  <div class="item-title">緊急聯絡人</div>
                  <div class="item-desc" v-text="item.emergencyName"></div>
                  <span class="success-alert"></span>
                </div>
                <div class="detail-item cursor-pointer" v-if="item.emergencyPhone" @click="getTextCopy($event)">
                  <div class="item-title">緊急聯絡人電話</div>
                  <div class="item-desc" v-text="item.emergencyPhone"></div>
                  <span class="success-alert"></span>
                </div>

                <div class="detail-item cursor-pointer" style="display: none;">
                  <div class="w-100 d-flex flex-nowrap">
                    <div class="item-title">地址區域碼</div>
                    <div class="item-desc" v-text="(item.cityCode && item.cityCode.length>0)? item.cityCode : ''"></div>
                  </div>
                  <span class="success-alert"></span>
                </div>
                <div class="detail-item cursor-pointer" style="display: none;">
                  <div class="w-100 d-flex flex-nowrap">
                    <div class="item-title">剩餘地址</div>
                    <div class="item-desc" v-text="item.address"></div>
                  </div>
                  <span class="success-alert"></span>
                </div>
              </div>
            </div>

            <div class="remove-btn" v-if="isOwner && !isExpired" @click="removeMember(item)">
              <Icon name="quit" size="24" />
              <span>移除</span>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import commonBusyAndErrorHandling from "@/assets/js/commonBusyAndErrorHandling";
import setHideNav from "@/assets/js/setHideNav";
import {mapState} from "vuex";

export default {
  mixins: [setHideNav, commonBusyAndErrorHandling],
  name: 'GroupDetailMemberEdit',
  data() {
    return {
      readInfoBusyName: 'GroupDetailMemberEdit',

      previousRouteName: '',

      pageId: null,
      groupData: null,
      journeyData: null,
      joinedMembers: {},

      isJoinOpen: false,
      membersMax: 20,
      setJoinSubmit: {
        groupSerial: -1,
        joinFlow: 0,
        joinLimit: 0,
      }
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.previousRouteName = from.name;
    });
  },
  components: {
  },
  computed: {
    ...mapState(['user']),

    isOwner() {
      return this.user && this.user.serial === this.groupData.groupMasterSerial;
    },
    isExpired: function() {
      const theDayAfterEndDate = moment(this.journeyData.endDate);
      theDayAfterEndDate.add(1, 'day');
      return moment().isAfter(theDayAfterEndDate);
    },
    numberOptions() {
      const options = [];
      for (let i = 1; i <= this.membersMax; i++) {
        options.push({ value: i, text: i.toString() });
      }
      return options;
    },
    joinFlowBoolean() {
      switch (this.groupData?.joinFlow) {
        case 0:
          return false;
        case 1:
          return true;
        default:
          return true;
      }
    },
    backRouterName() {
      return this.previousRouteName ? this.previousRouteName : 'GroupDetail';
    },
	},
  watch: {
    
  },
  async mounted() {
    this.pageId = this.$route.params.id;
    await this.refresh(this.pageId);
	},
  methods: {
    async refresh(journeyId) {
      await this.$store.dispatch('appendComponentBusy', this.readInfoBusyName);
      try {
        this.groupData = await this.$store.dispatch('api/getGroupDetailPromise', journeyId);
        this.journeyData = await this.$store.dispatch('api/getJourneyDetailPromise', this.groupData.journeys[0]);
        this.joinedOwner = await this.$store.dispatch('api/getUserDataPromise', this.groupData.groupMasterSerial);
        await this.refreshJoinedMemberList()
        this.isJoinOpen = this.joinFlowBoolean;
        this.showJoinFlowState();
        this.setJoinSubmit.groupSerial = this.groupData.serial;
        this.setJoinSubmit.joinLimit = this.groupData.joinLimit;
      } catch (error) {
        await this.$store.dispatch('appendErrorMsg', error.toString());
      } finally {
        await this.$store.dispatch('clearComponentBusy', this.readInfoBusyName);
      }
    },
    async refreshJoinedMemberList() {
      this.joinedMembers = {};
      this.joinedMembers = await this.$store.dispatch('api/getGroupMembersPromise', this.groupData.serial);
      if (this.joinedMembers.groupList) {
        const userItem = this.joinedMembers.groupList.find(item => item.serial === this.user.serial);
        if (userItem) {
          this.joinedMembers.groupList = this.joinedMembers.groupList.filter(item => item.serial !== this.user.serial);
          this.joinedMembers.groupList.unshift(userItem);
        }
      }
    },
    showJoinFlowState() {
      if(this.isJoinOpen) {
        this.setJoinSubmit.joinFlow = 1;
      } else {
        this.setJoinSubmit.joinFlow = 0;
      }
    },
    copyMsg(msg) {
      let node = document.createElement("span");
      node.innerText = msg;
      node.setAttribute('style', 'position:absolute;left:0;top:0;opacity:0;');
      document.body.appendChild(node);
      let selection = window.getSelection();
      selection.removeAllRanges();
      let range = document.createRange();
      range.selectNode(node);
      selection.addRange(range);

      try {
        var successful = document.execCommand('copy');
        var sysmsg = successful ? 'successful' : 'unsuccessful';
        console.log(sysmsg);
      } catch (err) {
        console.error(err);
      }

      selection.removeAllRanges();
      node.remove();
    },
    getTextCopy: function(event) {
      const target = event.currentTarget;
      let linkCopy = target.querySelector('.item-desc').innerText;
      this.copyMsg(linkCopy);
      const animated = target.querySelector('.success-alert');
      animated.classList.add('active');
      animated.onanimationend = () => {
        animated.classList.remove('active');
      }
    },
    async removeMember(item) {
      let r = confirm('確定將【' + item.realName + '】從團員中移除?');
      if(r) {
        await this.handleAsyncTask(async () => {
          const removeData = {
            groupSerial: item.groupSerial,
            memberSerial: item.userSerial,
          };
          const data = await this.$store.dispatch('api/postGroupRemovePromise', removeData);
          console.log(data);
          alert('成功將【' + item.realName + '】從團員中移除。');
          await this.refresh(this.pageId);
        });
      }
    },
    async updateMemberSettings() {
      await this.handleAsyncTask(async () => {
        const data = await this.$store.dispatch('api/postGroupSetJoinPromise', this.setJoinSubmit)
        console.log(data)
        await this.$router.push({ name: this.backRouterName });
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
@import "src/assets/scss/detailSubPage";
.group-detail-member-edit {
  .page-nav {
    .back-btn {
      @include smaller-than-medium {
        left: .5rem;
      }
    }
    .fs-h2 {
      margin-bottom: 1rem;
      @include smaller-than-medium {
        font-size: 1.125rem;
        line-height: 1.5;
        margin-bottom: 0;
      }
    }
    .fs-h5 {
      color: $color-bk-tp-25;
      margin-bottom: 0;
    }
    .option-btn {
      position: absolute;
      top: 1rem;
      right: 0;
      padding: .75rem 2.25rem;
      @include smaller-than-medium {
        top: 17px;
        right: .5rem;
        padding: .5rem 1.5rem;
        &.fs-body-18 {
          font-size: 16px;
          line-height: 1.5;
        }
      }
    }
  }
  .page-content {
    padding-top: 0;
    @include smaller-than-medium {
      padding: 0 0 2rem;
    }
  }
  .team-members-number {
    color: $color-bk-tp-85;
    padding: 36px 0;
    @include smaller-than-medium {
      padding: 28px 1rem;
    }
    &.with-hr:not(:last-child) {
      border-bottom: 1px solid $color-black-200;
    }
    .no-permission {
      color: $color-bk-tp-25;
    }
    .avatar-group {
      flex: 0 0 auto;
      margin-right: 36px;
      @include smaller-than-medium {
        margin-right: 2rem;
      }
      .edit-link {
        text-align: center;
        margin-top: 8px;
      }
    }
  }
  .detail-list {
    flex: 1 1 auto;
    //height: 62px;
    .detail-item {
      position: relative;
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: 12px;
      .item-title {
        flex: 0 0 auto;
        color: $color-bk-tp-25;
        padding-right: 12px;
      }
      .item-desc {
        flex: 1 1 auto;
        word-wrap: break-word;
      }
      .success-alert {
        position: absolute;
        left: 500%;
        white-space: nowrap;
        opacity: 0;
        //transform: translateY(-50%);
        &:before {
          content: '複製成功';
          color: $color-white;
          background: $color-black-800;
          font-size: $font-extra-small;
          padding: 3px 10px 3px;
          opacity: .7;
        }
      }
      .success-alert.active {
        top: -120%;
        left: 0;
        animation: alert 2s;
      }
    }
  }
  .remove-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: $color-warning-red;
    height: 2rem;
    padding: .5rem;
    margin: 1rem;
    span {
      font-size: $font-small;
      font-weight: $font-weight-bold;
      margin-left: .25rem;
    }
  }
}
</style>
